import React from 'react'
import { Container } from 'reactstrap'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>404 NOT FOUND</h1>
      <p>You just hit a route that doesn't exist... uh-oh.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
